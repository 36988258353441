import React, { lazy,useEffect,useState, useCallback} from 'react';
import './App.css';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { Auth , Hub } from "aws-amplify";
import { Router, Route, Switch } from "react-router-dom";
import history from './utils/history';
import { BusinessHeader,UnAuthHeader } from './components/Header';
import Routes from "./Routes";
import * as UserAction from './store/user/actions';
const App = () => {
  // const [childProps, setChildProps] = useState({
  //   isAuthenticated: false
  // });
  const dispatch = useDispatch();
  const mapState = useCallback(
    state => ({
      typeHeader: state.header.typeHeader,
      payload: state.header.typeHeader,
      isAuthenticated: state.user.isAuthenticated,
      isRedirectedForLogin: state.user.isRedirectedForLogin
    }),
    [] 
  );
  const { isAuthenticated ,typeHeader, payload = null } = useMappedState(mapState);
  
  useEffect(() =>{
    console.log("Hola mundo , esto se ejecutara")
    

    let updateUser = async () => {
    
        console.log("authState")
        let userState = await UserAction.checkIfIsAuthenticated(dispatch)
        console.log("el usser", userState)
    }
    Hub.listen('auth', updateUser) // listen for login/signup events
    updateUser() // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser) // cleanup


  }, [])




  useEffect(()=>{
    console.log("Hemos detectadoCambio", isAuthenticated)
  },[isAuthenticated])
  // useEffect(() => {
  //   console.log("Se va a activar cuando se autentique el user")
  //   console.log("Esto es algo isAuthenticatedRedux", isAuthenticatedRedux)
  //   setChildProps({
  //     isAuthenticated: isAuthenticatedRedux,
  //     userHasAuthenticated: false
  //   })
    
  //   Auth.currentSession()
  //   .then((res: any) => {
  //     const { idToken = {} } = res;
  //     const { payload } = idToken;
  //     const user = payload;

  //     console.log("user", user)
  //     console.log("tkn", idToken.jwtToken)
  //     // localStorageSet('tkn', idToken.jwtToken);


  //     console.log("Este es mi any", res)
  //   }).catch((err:any) => 
  //   {
  //     console.error('After login -> ' + err)

  //   })

  // }, [isAuthenticatedRedux]);
  return (
    <div>
    {/* <div className="header">
    {
    isAuthenticatedRedux ?  <BusinessHeader/> : <UnAuthHeader/> 
    }
    </div> */}
    <Router history={history}>
      <React.Suspense fallback="">
      <Routes childProps={{isAuthenticated}} />
      </React.Suspense>
    </Router>

    </div>

    

  );
}

export default App;
