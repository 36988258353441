import React, { lazy,useEffect,useState, useCallback} from 'react';
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import OpenRoute from "./components/OpenRoute";

import { useDispatch, useMappedState } from 'redux-react-hook';


const Login = lazy(() => import('./scenes/Login'));
const SignUp = lazy(() => import('./scenes/SignUp'));
const Home = lazy(() => import('./scenes/Home'));

const Search = lazy(() => import('./scenes/Search'));
const Templates = lazy(() => import('./scenes/Templates'));
const Carta = lazy(() => import('./scenes/Carta'));
const PedidoDetalle = lazy(() => import('./scenes/PedidoDetalle'));
const SearchBusiness = lazy(() => import('./scenes/SearchBusiness'));


export default ({ childProps }:any) =>
    <Switch>
        {/* <Route path={ROUTES.ROOT} exact component={NewReferral} />
        <Route path={ROUTES.MOTIVATION} exact component={Motivation} /> */}
        {/* <Route path='/' exact component={Root} props={childProps}/> */}
        {/* <UnauthenticatedRoute path='/' exact component={Login} props={childProps} /> */}
        {/* <AuthenticatedRoute path="/" exact component={Search} props={childProps} /> */}
        <OpenRoute path='/' exact component={Home} props={childProps} />
        <OpenRoute path='/c/:slug/:category?' exact component={Carta} props={childProps} />
        <OpenRoute path='/p/:cod_pedido_comercial' exact component={PedidoDetalle} props={childProps} />
        <OpenRoute path='/negocios/buscar/:category?' exact component={SearchBusiness} props={childProps} />
        <UnauthenticatedRoute path='/login' exact component={Login} props={childProps} />
        <UnauthenticatedRoute path='/sign-up' exact component={SignUp} props={childProps} />
        {/* <AuthenticatedRoute path='/home' exact component={Home} props={childProps} /> */}

        {/* <AuthenticatedRoute path="/search" exact component={Search} props={childProps} /> */}
        {/* <AuthenticatedRoute path="/templates" exact component={Templates} props={childProps} /> */}
    </Switch>;

