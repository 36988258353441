import { combineEpics } from 'redux-observable';

import headerEpics from './header/epics';
import userEpics from './user/epics';
import loadingEpics from './loading/epics';

const rootEpic = combineEpics(
  ...userEpics,
  ...loadingEpics,
  ...headerEpics
);

export default rootEpic;
