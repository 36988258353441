import Amplify, { Auth } from 'aws-amplify';    

import React ,  { useEffect,useCallback, useState, useRef ,createRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreContext } from 'redux-react-hook';
import { makeStore } from './store';
// import * as UserAction from './store/user/actions';

// import { useDispatch, useMappedState } from 'redux-react-hook';
// const dispatch = useDispatch();
// UserAction.getParameters();
const {store, persistor} = makeStore();
// dispatch(UserAction.setRedirectLogin({pedido,pedidoResume, cartaMenu, business}))

console.log("REACT_APP_NOT_SECRET_CODE12", process.env.REACT_APP_NOT_SECRET_CODE)
let configure = {
    Auth: {
        mandatorySignIn: false,
        region: process.env.REACT_APP_REGION,
        userPoolId:process.env.REACT_APP_USERPOOL_ID ,
        identityPoolId:process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            // domain: "cognito.growby.tech",
            redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
            responseType: "code",
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin']
          }
    },
    Storage: {
        // region: config.s3.REGION,
        // bucket: config.s3.BUCKET,
        // identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_ENDPOINT_NAME,
                // endpoint: 'http://localhost:3000',
                endpoint:"https://api.tusnexos.com/dev",
                region: process.env.REACT_APP_ENDPOINT_REGION
            }
        ]

    }
}
console.log("configure", configure)
Amplify.configure(configure);

ReactDOM.render(
    <StoreContext.Provider value={store}>
    <App />
    </StoreContext.Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
